import {
    FaMagnifyingGlass
} from "react-icons/fa6";
import "./styles/myShop.css"
import BuildShops from "../builders/BuildShops";
import Window from "../Window";
import CreateShop from "../builders/CreateShop";
import { useState } from "react";
import WarningModal from "../builders/WarningModal";

type Props = {}

const MyShopView = (props: Props) => {
    const [showCreateShop, setShowCreateShop] = useState<boolean>(false)
    const [blur, setBlur] = useState<boolean>(false)
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        setShowWarningModal(false);
    };

    const msg="This email already exist."

    const shops = [
        {
            name: "Ani's Cake",
            welcomeText: "Explore our world of beautiful cakes",
            status: true
        },
        {
            name: "Leon Photography",
            welcomeText: "Available to cover your weddings and parties",
            status: false
        },
        {
            name: "Nails by Orobosa",
            welcomeText: "A good nail gives the perfect touch",
            status: true
        },
        {
            name: "Genn's Confectionaries",
            welcomeText: "Lagos number one catering services",
            status: true
        },
        {
            name: "Sparrow",
            welcomeText: "Digital Vikings",
            status: true
        },
        {
            name: "Leon Photography",
            welcomeText: "Available to cover your weddings and parties",
            status: false
        },
        {
            name: "Nails by Orobosa",
            welcomeText: "A good nail gives the perfect touch",
            status: true
        },
        {
            name: "Genn's Confectionaries",
            welcomeText: "Lagos number one catering services",
            status: true
        },
        {
            name: "Leon Photography",
            welcomeText: "Available to cover your weddings and parties",
            status: false
        },
        {
            name: "Nails by Orobosa",
            welcomeText: "A good nail gives the perfect touch",
            status: true
        },
        {
            name: "Genn's Confectionaries",
            welcomeText: "Lagos number one catering services",
            status: true
        },
        {
            name: "Leon Photography",
            welcomeText: "Available to cover your weddings and parties",
            status: false
        },
        {
            name: "Nails by Orobosa",
            welcomeText: "A good nail gives the perfect touch",
            status: true
        },
        {
            name: "Genn's Confectionaries",
            welcomeText: "Lagos number one catering services",
            status: true
        },

    ]

    return (
        <div className="mshop_super_container">
            <div className="mshop_header_info">
                <p className="mshop_header_title">My Shops</p>
                <p className='mshop_desc_text'>A descriptive body text comes here</p>
                <button type="button" className="mshop_addNewmshopomer_btn" onClick={() => setShowCreateShop(true)}>+ Create Shop</button>
            </div>
            <div className='mshop_search_bar_container'>
                <div className='mshop_search_box'>
                    <FaMagnifyingGlass size={30} color='#667085'/>
                    <input placeholder='Search' type='search' id='mshop_search_input'/>
                </div>
                <select className="mshop_search_filter">
                    <option value="">Filter</option>
                    <option value="date_filter">Filter by date</option>
                    <option value="amount_filter">Filter by amount</option>
                </select>
            </div>
            <div className="mshop_container">
                <div className="mshop_header">
                    <p className="mshop_text">Shop Name</p>
                    <p className="mshop_text">Description</p>
                    <p className="mshop_text">Status</p>
                </div>
                <hr className="mshop_hr" />
                {shops.map((shop) => BuildShops(shop))}  
                {<WarningModal msg={msg} onClose={handleCloseModal}/>}

            </div>
            {
                showCreateShop && 
                CreateShop(setShowCreateShop, setBlur)
            }
        </div>
  )
}

const MyShop = () => {
    const content = {
        currentView: <MyShopView />,
        activeId: 9
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}

export default MyShop