import {
    FaCircleLeft
} from "react-icons/fa6";
import "./styles/createProduct.css"
import Window from "../Window";

type Props = {}

const CreateProductView = (props: Props) => {
return (
    <div className="cProduct_super_container">
        <div className="cProduct_header_info">
            <FaCircleLeft size={30} color="#B20C02"/>
            <p className="cProduct_header_title">Ani Cake Shop</p>
        </div>
        <hr className="cProduct_hr"/>
        <div className="cProduct_wrapper">
            <p className="cProduct_header_title">Create Product</p>
            <form className="cProduct_form">
                <label>
                    Name of Product <br/>
                    <input className="cProduct_i_prod_name" placeholder="Name of product" type="text"/>
                </label>
                <label>
                    Product Description <br/>
                    <input className="cProduct_i_prod_desc" type="text" placeholder="Product description"/>
                </label>
                <div className="cProduct_i_fieldset">
                    <label>
                        Price <br/>
                        <input className="cProduct_i_prod_price" type="text" placeholder="price"/>
                    </label>
                    <label>
                        Available Quantity <br/>
                        <input className="cProduct_i_prod_avail" type="text" placeholder="Quantity"/>
                    </label>
                </div>

            </form>

        </div>
        
    </div>
  )
}

const CreateProduct = () => {
    const content = {
        currentView: <CreateProductView/>,
        activeId: 10
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}

export default CreateProduct