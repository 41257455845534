import React from 'react'
import {
    FaBagShopping,
    FaShop,
    FaMagnifyingGlass,
    FaPeopleGroup,
    FaLinesLeaning,
    FaRegFileLines

} from "react-icons/fa6";
import Window from '../Window';
import "./styles/transactions.css"
import BuildTransactionLog from '../builders/BuildTransactionLog';

type Props = {}

const TransactionsView = (props: Props) => {

       const transactions = [
        {
            id: 1,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 2,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 3,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: false,
            tableTitle: "Details"
        },
        {
            id: 4,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 5,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 6,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 7,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 8,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 9,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: false,
            tableTitle: "Details"
        },
        {
            id: 10,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
        {
            id: 11,
            name: "Temitope Alabi",
            amount: "N30,000",
            merchant: "Cakes and Bakery",
            status: true,
            tableTitle: "Details"
        },
    ]

  return (
    <div className='trans_sup_cont'>
        <h2 className='trans_title1'>Transactions</h2>
        <div className='trans_overview'>
            <div className='trans_overview_sec'>
                <FaBagShopping size={30} color='#EA580C' className='trans_overview_icn'/>
                <div>
                    <p className='trans_overview_desc'>Total Transactions</p>
                    <p className='trans_overview_amt'>N209,456,059</p>
                </div>
            </div>
            <div className='trans_overview_sec'>
                <FaBagShopping size={30} color='#27AE60' className='trans_overview_icn'/>
                <div>
                    <p className='trans_overview_desc'>Successful Transactions</p>
                    <p className='trans_overview_amt'>5545</p>
                </div>
            </div>
            <div className='trans_overview_sec'>
                <FaBagShopping size={30} color='#EB5757' className='trans_overview_icn'/>
                <div>
                    <p className='trans_overview_desc'>Failed Transactions</p>
                    <p className='trans_overview_amt'>38</p>
                </div>
            </div>
            <div className='trans_overview_sec'>
                <FaLinesLeaning size={30} color='#9333EA' className='trans_overview_icn'/>
                <div>
                    <p className='trans_overview_desc'>Total Orders</p>
                    <p className='trans_overview_amt'>1,326</p>
                </div>
            </div>
        </div>
        <div className='trans_trans_cont'>
            <div className='trans_search_cont'>
                <div className='trans_search_input_wrpr'>
                    <input type="search" className='trans_search_box'/>
                    <FaMagnifyingGlass size={15} color='#OOOOOO' className='trans_search_icn'/>
                </div>
                <button className='trans_search_btn'>
                    <p>Filter</p>
                </button>
            </div>
            <div className='trans_trans_hd'>
                <p className='trans_trans_hd_txt'>Customer</p>
                <p className='trans_trans_hd_txt'>Amount</p>
                <p className='trans_trans_hd_txt'>Merchant</p>
                <p className='trans_trans_hd_txt'>Status</p>
                <p className='trans_trans_hd_txt'>Table Title</p>
            </div>
            <div>
                {transactions.map((transaction) => BuildTransactionLog(transaction))} 
            </div>
        </div>

    </div>
  )
}
const Transactions = () => {
    const content = {
        currentView: <TransactionsView />,
        activeId: 2
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}
export default Transactions