import axios from 'axios'

const HandleSubmit = async (e: any, payload: any, url: string, method: string,  setStatus?: any, setMessage?: any, setUser?: any, accessToken?: string, customheaders?: any) => {
      e.preventDefault()
      const url_add = url
      const body = payload
      let response: any;
      let err: any;
      const token =  accessToken
      const headers = customheaders

      try {
            if (method === "POST") {
                  response = await axios.post(url, body, headers);
                  if (setUser !== undefined) {
                        setUser(response.data.response)
                  }
            } else if (method === "PUT") {
                  response = await axios.put(url, body, headers);
                  if (setUser !== undefined) {
                        setUser(response.data.response)
                  }
            } else if (method === "DELETE") {
                      const config = {
                        method: 'delete',
                        url: url,
                        headers: { "Authorization": `Bearer ${token}` },
                        data: {},
                      };
                  response = await axios(config);
            }
            if (response.data.status === 'error') {
                  setStatus(false);
                  setMessage(response.data.response)
                  return false;
            } else {
                  setMessage(response.data.response)
                  setStatus(true);
                  return true;
            }
      } catch (error) {
            console.error(error);
            setStatus(false);
            return false;
      }
}

export default HandleSubmit
