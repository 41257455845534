import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

type Props = {}

const Login = (props: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  return (
<div></div>
  );
}

export default Login;
