import {useState} from 'react'
import "./styles/buildProduct.css"
import {
  FaRegTrashCan,
  FaPencil
} from "react-icons/fa6";

type BuildProductProps = {
    image: string,
    qty: string,
    price: string, 
    name: string,
    text: string,
}

const BuildProduct = (product: BuildProductProps) => {
  return (
    <div className="product_section">
        <div className='product_info_div'>
            <img src={product.image}/>
            <div className='product_wrapper'>
              <div className='product_info_ln1'>
                <p className="product_name product_txt">{product.name}</p>
                <p className="product_qty product_txt">Quntity: {product.qty}</p> 
              </div>
              <div className='product_info_ln2'>
                <p className="product_desc product_txt">{product.text}</p> 
                <p className="product_price product_txt">N{product.price}</p> 
              </div>
            </div>  
        </div> 
        <div className="product_action_div">
            <button type='button' className='product_del_btn'><FaRegTrashCan size={15}/></button>
            <button type='button' className='product_edit_btn'><FaPencil size={15}/></button>
        </div>
    </div>
  )
}

export default BuildProduct