import React, { ChangeEvent, useEffect, useState } from 'react'
import IbillsLogo from "./assets/amaps.png"
import ImgPlaceHolder from "./assets/imagePlaceholder.png"
import "./styles/businessVerification.css"
import {
    FaCircleCheck,
    FaUpload
} from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { env } from '../shared/assets/environment/envSelector'
import HandleSubmit from './components/HandleSubmit'
import { authState } from '../shared/assets/slices/authSlice';
import { useSelector } from 'react-redux';
import SuccessModal from './builders/SuccessModal';
import WarningModal from './builders/SuccessModal';
// import handleCloseModal from './components/handleCloseModal';


type Props = {}

const BusinessVerification = (props: Props) => {

    const payload = {
        profile_picture: null,
        business_name: "",
        business_certificate: null,
        rc_number: "",
        rc_certificate: null,
        tax_id: "",
        tax_certificate: null,
        id_type: "",
        upload_id: null,
        bvn: "",
        enter_bvn_number: "",
        bank_name: "",
        account_number: "",
        email: "",
    }

    const { busVerification, idChoices, bankChoices } = env
    const [completed, setCompleted] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(1)
    // State to hold image data
    const [idtypes, setIdTypes] = useState([]);
    const [idName, setIdName] = useState('');
    const [banktypes, setBankTypes] = useState([]);
    const [bankName, setBankName] = useState('');
    const [packetStatus, setPacketStatus] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<boolean>(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    var [message, setMessage] = useState<string>("");
    const [imageData, setImageData] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imagename, setImageName] = useState<string | null>(null)
    const [imagenames, setImageNames] = useState<any>(payload)
    const [formData, setFormData] = useState<any>(payload)
    const { getUser } = useSelector((state: { auth: authState }) => state.auth);
    const { access_token, user } = getUser
    const { email } = user


    const navigate = useNavigate()


    const handleBusVerificationUpload = async (e: any) => {
        e.preventDefault()
        const headers = {
            headers: { "Content-type": "multipart/formdata" },
        }
        const formdata = new FormData()


        Object.keys(formData).forEach(key => {
            if (formData[key] instanceof File) {
                // For File objects
                formdata.append(key, formData[key], formData[key].name);
            } else {
                // For string or null values
                formdata.append(key, formData[key] ?? "");
            }
        });

        const success = await HandleSubmit(e, formdata, busVerification, "PUT", setPacketStatus, setStatusMessage, undefined, access_token, headers);
        if (success) {
            setMessage("Your record has been updated successfully!")
            setShowSuccessModal(true)
        }else{
            setShowWarningModal(true)
        }
    }

    // Update image data on input change
    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const { name, files } = e.target;
            setFormData((prevData: any) => ({
                ...prevData,
                [name]: files[0],
            }));
            if (name === "profile_picture") {
                setImageData(files[0])
            }

            setImageNames((prevData: any) => ({
                ...prevData,
                [name]: files[0].name
            }))
        }
        if (!e.target.files) {
            const { name, value } = e.target;
            setFormData((prevData: any) => ({
                ...prevData,
                [name]: value,
            }));
        }

    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target
        if (name === "id_type") {
            setIdName(value)
        }
        else if (name === "bank_name") {
            setBankName(value)
        }
    };


    const handleCloseModal = () => {
        setShowSuccessModal(false);
        setShowWarningModal(false);
    };

    useEffect(() => {
        // Update the formData
        if (formData) {
            formData.email = email
            formData.id_type = idName
            formData.bank_name = bankName
        }
    }, [formData]);

    useEffect(() => {
        // Generate the image URL and update state
        if (imageData) {
            const url = URL.createObjectURL(imageData);
            setImageUrl(url);

            // Clean up
            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [imageData]);

    useEffect(() => {
        fetch(idChoices)
            .then((response) => response.json())
            .then((data) => {
                setIdTypes(data.idtypes);
            });

        fetch(bankChoices)
            .then((response) => response.json())
            .then((data) => {
                setBankTypes(data.banknames);
            });
    }, []);

    useEffect(() => {
        if (statusMessage) {
        }
    });


    return (
        <div className='bus_ver_super_contaner'>
            <div className='bus_ver_header'>
                <img src={IbillsLogo} alt='ibils logo' className='amapgs-logo' />
                <div className='bus_ver_hd_wrapper'>
                    <div>
                        <h2 className='bus_ver_hd_title'>Business Verification</h2>
                        <p className='bus_ver_hd_txt'>Upgrade for more. Verify your business, unlock advanced features.</p>
                    </div>
                    <button className='bus_ver_visit_branch_btn' onClick={() => navigate("/merchant/physical-verification")}>Visit any UBA branch for physical verification</button>
                </div>
            </div>
            <div className='bus_ver_main'>
                <ul className='bus_ver_aside_tab'>
                    <li className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>Business Details <FaCircleCheck className="profile_check_icon" size={15} color={completed ? "#6FCF97" : "grey"} /></li>
                    <li className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>KYC <FaCircleCheck className="profile_check_icon" size={15} color={completed ? "#6FCF97" : "grey"} /></li>
                    <li className={activeTab === 3 ? "active" : ""} onClick={() => setActiveTab(3)}>Bank Details <FaCircleCheck className="profile_check_icon" size={15} color={completed ? "#6FCF97" : "grey"} /></li>
                </ul>
                <div className='bus_ver_content_container'>
                    {activeTab === 1 &&
                        <div className='bus_ver_content'>
                            <p className='bus_ver_sec_title'>Business Details</p>
                            <div className='bus_ver_img_sec'>
                                <img src={imageUrl ? imageUrl : ImgPlaceHolder} alt="placeholder" className="bus_ver_img_logo" />
                                <p className='bus_ver_img_txt'>Max 500px by 500px</p>
                                <label htmlFor="profile_picture" className="bus_ver_upload_img_btn">
                                    <FaUpload /> Upload Logo
                                </label>
                                <input type="file" name="profile_picture" id="profile_picture" onChange={handleImageChange} hidden />
                                <span className='bus_ver_img_span_tag'>{imagenames ? imagenames.profile_picture : ""}</span>
                            </div>
                            <div className='content_upload_frm'>
                                <>
                                    <p className='content_title'>Business Name</p>
                                    <input className="content1_input" type='text' name='business_name' id='business_name' onChange={handleImageChange} />
                                    <label htmlFor="business_certificate" className='bus_ver_upload_btn'><FaUpload /> Upload Business Name Certificate
                                    </label>
                                    <input type="file" name="business_certificate" id="business_certificate" hidden onChange={handleImageChange} />
                                    <span className='bus_ver_img_span_tag'>{imagenames ? imagenames.business_certificate : ""}</span>
                                </>
                                <>
                                    <p className='content_title'>RC Number</p>
                                    <input className="content1_input" name='rc_number' id='rc_number' type='text' onChange={handleImageChange} />

                                    <label htmlFor="rc_certificate" className='bus_ver_upload_btn'><FaUpload /> Upload Certificate of Incorporation
                                    </label>
                                    <input type="file" name="rc_certificate" id="rc_certificate" hidden onChange={handleImageChange} />
                                    <span className='bus_ver_img_span_tag'>{imagenames ? imagenames.rc_certificate : ""}</span>
                                </>
                                <>
                                    <p className='content_title'>Tax ID</p>
                                    <input className="content1_input" name='tax_id' id='tax_id' type='text' onChange={handleImageChange} />

                                    <label htmlFor="tax_certificate" className='bus_ver_upload_btn'><FaUpload /> Upload Tax ID Certificate
                                    </label>
                                    <input type="file" name="tax_certificate" id="tax_certificate" hidden onChange={handleImageChange} />
                                    <span className='bus_ver_img_span_tag'>{imagenames ? imagenames.tax_certificate : ""}</span>
                                </>
                            </div>
                            <button className='bus_ver_action_button' onClick={handleBusVerificationUpload}>Next</button>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div className='bus_ver_content'>
                            <p className='bus_ver_sec_title'>Personal Identity (Business Owner/Director)</p>
                            <div className='content_upload_frm'>
                                <>
                                    <select value={idName} onChange={handleSelectChange} className="content_select" name='id_type' id='id_type'>
                                        <option key={1} value=''>Select ID Type</option>
                                        {idtypes.map(([value, label]) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="upload_id" className='bus_ver_upload_btn'><FaUpload /> Upload ID
                                    </label>
                                    <input type="file" name="upload_id" id="upload_id" hidden onChange={handleImageChange} />
                                    <span className='bus_ver_img_span_tag'>{imagenames ? imagenames.upload_id : ""}</span>
                                </>
                            </div>
                            <div className='content_upload_frm'>
                                <>
                                    <p className='content_title'>Enter BVN Number</p>
                                    <input className="content_select" type='number' name='bvn' id='bvn' onChange={handleImageChange} />
                                </>
                            </div>
                            <button className='bus_ver_action_button' onClick={handleBusVerificationUpload}>Next</button>
                        </div>
                    }
                    {activeTab === 3 &&
                        <div className='bus_ver_content'>
                            <p className='bus_ver_sec_title'>Bank Details</p>
                            <div className='content_upload_frm'>
                                <>
                                    <p className='content_title'>Bank</p>
                                    <select value={bankName} onChange={handleSelectChange} className='content_select' name='bank_name' id='bank_name'>
                                        <option key={2} value=''>Select Bank</option>
                                        {banktypes.map(([value, label]) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                    <p className='content_title'>Account Number</p>
                                    <input name="account_number" id="account_number" className="content_input" type='number' placeholder='Enter account number' onChange={handleImageChange} />
                                </>
                            </div>
                            <button className='bus_ver_action_button' onClick={handleBusVerificationUpload}>Submit</button>
                        </div>
                    }

                </div>
            </div>
            {showSuccessModal && <SuccessModal msg={message} onClose={handleCloseModal} />}
            {showWarningModal && <WarningModal msg={message} onClose={handleCloseModal} />}

        </div>
    );
}

export default BusinessVerification

