
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './shared/assets/bootstrap/css/bootstrap.min.css'
import './shared/assets/mobirise/css/mbr-additional.css'
import amapgs from './shared/assets/images/amaps.png'

type Props = {}

const Landing = (props: Props) => {
  const navigate = useNavigate()
  return (<div>
    <section data-bs-version="5.1" className="header09 cid-uaFB94VDwZ mbr-fullscreen" id="header09-q">
	
	
	<div className="container">
    <div className="row" ><a href=""><img src={amapgs} alt="" style={{width:'130px', height: '50px', position: 'fixed', top: '30px', left: '30px'}} /></a></div>
		<div className="row justify-content-center">
			<div className="content-wrap col-12 col-md-8">
				<h2 className="mbr-section-title mbr-fonts-style mbr-white mb-4 display-1">
					<strong>Let us Empower your Innovation</strong>
				</h2>
				
				<h5 className="mbr-fonts-style mbr-text mbr-white mb-4 text-center">
				Unleash the full potential of commerce with our Payment Infrastructure as a Service. Providing a robust foundation for both buyers and sellers, our platform offers the ultimate in transactional ease and reliability. Elevate your business with a powerful payment gateway designed for seamless integration and scalability
				</h5>
				<p className='display-7 mbr-fonts-style mbr-text mbr-white mb-4 text-center'> Choose your path and let's thrive together.</p>
				<div className="mbr-section-btn">
					<a className="btn btn-danger display-7" href="customer/">Customer</a>
					<a className="btn btn-danger display-7" href="merchant/">Merchant</a>
				</div>
			</div>
		</div>
	</div>
</section>
  </div>)
}

export default Landing