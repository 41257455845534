import { useState } from "react";
import {
    FaMagnifyingGlass
} from "react-icons/fa6";
import "./styles/payouts.css"
import Window from "../Window";
import RequestPayoutModal from "../builders/RequestPayoutModal";

type Props = {}

const PayoutsView = (props: Props) => {
    const [showAddCustomer, setShowAddCustomer] = useState<boolean>(false)
    const [showReqPayout, setShowReqPayout] = useState<boolean>(false)
    const [blur, setBlur] = useState<boolean>(false)

  return (
    <div className='payouts_super_container1'>
        <div className= {blur? "payouts_super_container_blur" : "payouts_super_container"}>
            <div className="payouts_header_info">
                <p className="payouts_header_title">Payouts</p>
                <p className='payouts_desc_text'>A descriptive body text comes here</p>
                <button type="button" className="payouts_addNewpayoutsomer_btn" onClick={() => {setShowReqPayout(true); setBlur(true)}}>+ Request Payout</button>
            </div>
            <div className='payouts_search_bar_container'>
                <div className='payouts_search_box'>
                    <FaMagnifyingGlass size={30} color='#667085'/>
                    <input placeholder='Search' type='search' id='payouts_search_input'/>
                </div>
                <select className="payouts_search_filter">
                    <option value="">Filter</option>
                    <option value="date_filter">Filter by date</option>
                    <option value="amount_filter">Filter by amount</option>
                </select>
            </div>
            <div className="payouts_dashboard_container">
                <div className="payouts_dashboard_section">
                    <p className="payouts_dashboard_section_title">Wallet Balance</p>
                    <p className="payouts_dashboad_text">N500,000</p>
                </div>
                <div className="payouts_dashboard_section">
                    <p className="payouts_dashboard_section_title">Available Balance</p>
                    <p className="payouts_dashboad_text available_balance">N200,000</p>
                </div>
                <div className="payouts_dashboard_section">
                    <p className="payouts_dashboard_section_title">Total Requested</p>
                    <p className="payouts_dashboad_text">N1,000,000</p>
                </div>
                <div className="payouts_dashboard_section">
                    <p className="payouts_dashboard_section_title">Total Received</p>
                    <p className="payouts_dashboad_text">N800,000</p>
                </div>
            </div>
            <div className="payouts_container">
                <div className="payouts_header">
                    <p className="payouts_text">Amount</p>
                    <p className="payouts_text">Date Requested</p>
                    <p className="payouts_text">Status</p>
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_pending">Pending</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_pending">Pending</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
                <div className="payouts_section">
                    <p className="payouts_text_ln">N50,000</p>
                    <p className="payouts_text_ln">14 June, 2022</p>
                    <div className="payouts_status_wrapper">
                        <p className="payouts_status_paid">Paid</p>  
                    </div>  
                </div>
                <hr className="payouts_hr" />
            </div>
        </div>
        {showReqPayout && RequestPayoutModal(setShowReqPayout, setBlur)}
    </div>
  )
}

const Payouts= () => {
    const content = {
        currentView: <PayoutsView />,
        activeId: 7
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}

export default Payouts