import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

type Props = {
    currentView: any
    activeId: number
}

const Window = (props: Props) => {
    const [activeId, setActiveId] = useState<number>(1)
    const navigate = useNavigate()
  return (
<div></div>
  )
}

export default Window