import React from 'react'
import {
    FaMagnifyingGlass
} from "react-icons/fa6";
import "./styles/transactions.css"
import Window from '../Window';
import BuildTransaction from '../builders/BuildTransaction';

const TransactionsView = () => {
    const transactions =  [
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: false,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },
        {
            refNo: "98599TF",
            customerName: "Yemi Adamu",
            date: "12 Jan, 2023 02:35",
            item: "Mentorship Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,000"
        },

    ]


  return (
    <div className='view_screen'>
        <div className="t_super_container">
            <p className="t_header_title">Transactions</p>
            <p className='t_desc_text'>A descriptive body text comes here</p>
            <div className='t_row2_container'>
                <div className='t_search_bar_container'>
                    <div className='t_search_box'>
                        <FaMagnifyingGlass size={30} color='#667085'/>
                        <input placeholder='Search' type='search' id='t_search_input'/>
                    </div>
                    <select className="t_search_filter">
                        <option value="">Filter</option>
                        <option value="date_filter">Filter by date</option>
                        <option value="amount_filter">Filter by amount</option>
                    </select>
                </div>
                <div className='t_exp_btns_wrapper'>
                    <button className='t_exp_btn' type='button'>Export CSV</button>
                    <button className='t_exp_btn' type='button'>Export PDF</button>
                </div>
            </div>
            <div className="t_container">
                <div className="t_section t_header">
                    <p className="t_text">Ref. No</p>
                    <p className="t_text">Name</p>
                    <p className="t_text">Date</p>
                    <p className="t_text">Item</p>
                    <p className="t_text">Status</p>
                    <p className="t_text">Mode of Payment</p>
                    <p className="t_text">Amount</p>
                </div>
                <hr className="translog_hr"/>
                {
                    transactions.map((transaction) => BuildTransaction(transaction))
                }
            </div>
        </div>
    </div>
  )
}


const Transactions = () => {
    const content = {
        currentView: <TransactionsView />,
        activeId: 2
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}

export default Transactions