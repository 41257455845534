import { 
    FaArrowDown,
    FaArrowUp
} from "react-icons/fa6";
import BuildLineGraph from "../builders/BuildLineGraph";
import "./styles/dashboard.css"
import Window from "../Window";
import UnvarifiedWelcomeOverlay from "../builders/UnvarifiedWelcomeOverlay";
import { useState } from "react";
import BuildTransaction from "../builders/BuildTransaction";


const DashboardView = () => {

    const transactions =  [
        {
            customerName: "Grace Doris",
            date: "12/02/2023",
            item: "Coaching Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,500"
        },
        {
            customerName: "Adams John",
            date: "12/02/2023",
            item: "Mentorship",
            successStatus: false,
            paymentMethod: "Card",
            paymentAmount: "20,500"
        },
        {
            customerName: "Grace Doris",
            date: "12/02/2023",
            item: "Coaching Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,500"
        },
        {
            customerName: "Grace Doris",
            date: "12/02/2023",
            item: "Coaching Plan",
            successStatus: true,
            paymentMethod: "Card",
            paymentAmount: "20,500"
        }
    ]
    const [verified, setVerified] = useState<boolean>(false)
  return (
    <div>
        <div className={verified? 'view_screen' : "transactions_super_container_blur"}>
            <div className='transactions_super_container'>
                <div className='trans_container'>
                    <p className='trans_title'>Wallet Balance</p>
                    <div className='trans_wrapper'>
                        <p className='trans_amount'>200,800.00</p>
                        <div className='trans_chart_up'>
                            <FaArrowUp />
                            <p className='trans_percent'>0.50%</p>
                        </div>
                    </div>
                </div>
                <div className='trans_container'>
                    <p className='trans_title'>Available Balance</p>
                    <div className='trans_wrapper'>
                        <p className='trans_amount'>70,500.00</p>
                    </div>
                </div>
                <div className='trans_container'>
                <p className='trans_title'>Failed Transactions</p>
                    <div className='trans_wrapper'>
                        <p className='trans_amount'>50,000.00</p>
                        <div className='trans_chart_up'>
                            <FaArrowUp />
                            <p className='trans_percent'>0.70%</p>
                        </div>
                    </div>
                </div>
                <div className='trans_container'>
                    <p className='trans_title'>New Customers</p>
                    <div className='trans_wrapper'>
                        <p className='trans_amount'>20</p>
                        <div className='trans_chart_down'>
                            <FaArrowDown />
                            <p className='trans_percent'>0.20%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="revenue_super_container">
                <p className="header_title">Revenue</p>
                <div className='revenue'>
                    <div className='rev_container'>
                        <div className='rev_wrapper1'>
                            <div className='rev_gross_color'></div>
                            <p className='rev_title'>Gross Volume</p>
                        </div>
                        <div className='rev_wrapper2'>
                            <p className='rev_amount'>200,000</p>
                            <div className='rev_chart_up'>
                                <FaArrowUp />
                                <p className='rev_percent'>0.20%</p>
                            </div>
                        </div>
                    </div>
                    <div className='rev_container'>
                        <div className='rev_wrapper1'>
                            <div className='rev_net_color'></div>
                            <p className='rev_title'>Net Volume</p>
                        </div>
                        <div className='rev_wrapper2'>
                            <p className='rev_amount'>N45,000</p>
                            <div className='rev_chart_up'>
                                <FaArrowUp />
                                <p className='rev_percent'>20%</p>
                            </div>
                        </div>
                    </div>
                    <div className="rev_filter_container">
                        <select className="rev_filter">
                            <option value="">Filter</option>
                            <option value="date_filter">Filter by date</option>
                            <option value="amount_filter">Filter by amount</option>
                        </select>
                    </div>
                </div>
                <div className="line_graph">
                    <BuildLineGraph />
                </div>
            </div>
            <div className="translog_super_container">
                <p className="header_title">Transactions</p>
                <div className="translog_container">
                    <div className="translog_header">
                        <p className="tlog_text">Customer</p>
                        <p className="tlog_text">Date</p>
                        <p className="tlog_text">Item</p>
                        <p className="tlog_text">status</p>
                        <p className="tlog_text">Payment Method</p>
                        <p className="tlog_text">Amount</p>
                    </div>
                    <hr className="translog_hr"/>
                    {
                        transactions.map((transaction) => BuildTransaction(transaction))
                    }
                </div>
            </div> 
        </div>
        {!verified &&
            <UnvarifiedWelcomeOverlay setVarified={setVerified} />
        }
    </div>
  )
}


const Dashboard = () => {
    const content = {
        currentView: <DashboardView />,
        activeId: 1
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}
export default Dashboard