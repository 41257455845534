import React, { useState } from "react";
import "./styles/signup.css";
import merchHero from "./assets/2.png"
import iBillsLogo from "./assets/amaps.png"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authState, setDisplayPart1, setDisplayPart2, setGetCode1, setGetCode2, setResendCode1, setResendCode2, setPacketStatus, setMessage, setFirstName, setLastName, setPhoneNumber, setPassword, setEmail, setEmailOtp, setPhoneOtp } from '../shared/assets/slices/authSlice';
import HandleSubmit from "./components/HandleSubmit";
import WarningModal from "./builders/WarningModal";
import ResendCodeButton from './components/countdownbutton';
import { env } from "../shared/assets/environment/envSelector";

type Props = {}

const SignUp = (props: Props) => {
  const dispatch = useDispatch();
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const { message, firstName, lastName, phoneNo, password, email } = useSelector((state: { auth: authState }) => state.auth);

  const { signup } = env;

  const navigate = useNavigate()


  const payload = {
    email: email,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNo,
    password: password
  };


  const createAccount = async (e: any) => {
    e.preventDefault();
    const headers = {
      headers: { "Content-type": "application/json" },
    }
    const success = await HandleSubmit(e, payload, signup, "POST", setPacketStatus, setStatusMessage, undefined, undefined, headers);
    if (success) {
      navigate("/merchant/verifyotp")
    }
  };


  return (
    <main className='signup'>
      <div className="column1">
        <div className="wrapper1">
          <img src={iBillsLogo} className='amapgs_logo' alt="instant bills pay logo" onClick={() => navigate("/merchant")} />
          <form className='signup_form' method="POST">
              <div className="part1">
                <h2 className='title'>Merchant Account Creation</h2>
                <div className='create_account_data'>
                  <section className="name_field">
                    <label className='firstname_l'>First Name
                      <input className='firstname' type="text" onChange={(e) => dispatch(setFirstName(e.target.value))} />
                    </label>
                    <label className='lastname_l'>Last Name
                      <input className='lastname' type="text" onChange={(e) => dispatch(setLastName(e.target.value))} />
                    </label>
                  </section>
                  <section className="phone_field">
                    <label className='country_code_l'>Country
                      <input className='country_code' placeholder="country code" type="text" />
                    </label>
                    <label className='phone_l'>Phone Number
                      <input className='phone' placeholder="+234" type="tel" onChange={(e) => dispatch(setPhoneNumber(e.target.value))} />
                    </label>
                  </section>
                  <label className='email_l'>Email
                    <input className='email' placeholder="Email" type="email" onChange={(e) => dispatch(setEmail(e.target.value))} />
                  </label>
                  <label className='password_l'>Password
                    <input className='password' placeholder="Password" type="password" onChange={(e) => dispatch(setPassword(e.target.value))} />
                  </label>
                </div>
                <p className="warning">{message ? message : null}</p>
                <button className="button1"
                  onClick={createAccount}>Create Account
                </button>
                <h2 className="create_account_title">Already have an account?</h2>
                <button className="button2" onClick={() => navigate("/merchant/signin")}>Sign in</button>
              </div>
          </form>
        </div>
      </div>
      <div className='column2'>
        <img src={merchHero} alt="hero_img" className="hero_img_signup" loading="lazy" />
      </div>

    </main>
  );
}

export default SignUp;
