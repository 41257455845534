import React from 'react'
import ibills from "./assets/amaps.png"
import amapgsTransparent from "./assets/amapgs-transparent.png"
// import ibills from "./assets/ibillslogo.png"
import "./styles/landing.css"
import iconHolder from "./assets/icon.png"
import safeTransaction from "./assets/safe_transaction.png"
import globeImage from "./assets/global.png"
import happy from "./assets/happy.png"
import review1 from "./assets/review1.png"
import review2 from "./assets/review2.png"
import twitter from "./assets/twittter.png"
import instagram from "./assets/instagram.png"
import coolicon from "./assets/coolicon.png"
import apiIcon from "./assets/api.png"
import globeIcon from "./assets/globe.png"
import noHiddenFeesIcon from "./assets/nohiddenfees.png"
import securedIcon from "./assets/secure.png"
import { useNavigate } from 'react-router-dom'
import UBALogo from "./assets/ojapay logo.png"
// import UBALogo from "./assets/UBALogo.jpg"

type Props = {}

const Landing = (props: Props) => {
  const navigate = useNavigate()
  return (
    <div className='landing'>
        <header className='header_container'>
            <div className='header_tab'>
                <img src={ibills} alt="ibills_logo" className='amapgs_logo' onClick={ () => navigate("/merchant")}/>
                <ul className='header_links'>
                    <li>About Us</li>
                    <li>Contact Us</li>
                    <li>Benefits</li>
                </ul>
                <div className='header_button_div'>
                    <button  type='button' className='signin_btn' onClick={ () => navigate("signup")}>Sign Up</button>
                    <button type='button' className='login_btn' onClick={ () => navigate("signin")}>Login</button>
                </div>
            </div>
            <div className='text_banner_container'>
                <div className='banner_title'>
                    <h1>The Pan-African Global</h1>
                    <h1>Payment Platform</h1>
                </div>
                <p className='banner_message'>Seamlessly Transforming Transactions Across <br/> Borders for a Unified Future</p>
                <button type='button' className='get_started_button' onClick={ () => navigate("signup")}>Get Started</button>
            </div>
                
        </header>
        <main className='main'>
            <div className='main_row1'>
                <div className='row1_banner_text_container'>
                    <h2 className='row1_banner_text_title'>Receive payment for your goods and services</h2>
                    <p className='row1_banner_text_msg'>Effortless payments for your goods and services. Experience <br/> seamless transactions with our secure and efficient solutions.</p>

                </div>
                <div className='row1_section_container'>
                    <section className='row1_section'>
                        <div className='row1_section_icon_wrapper'>
                            <img src={iconHolder} alt="placeholder" />
                        </div>
                        <p className='row1_section_title'>Bills Collection</p>
                        <p className='row1_section_text'>Efficient bills collection made easy. Streasectionne and simplify your payments.</p>
                    </section>
                    <section className='row1_section'>
                        <div className='row1_section_icon_wrapper'>
                            <img src={globeIcon} alt="globe icon" />
                        </div>
                        <p className='row1_section_title'>Global</p>
                        <p className='row1_section_text'>Simplify Pan-African and global diaspora payments with our seamless solutions.</p>
                    </section>
                    <section className='row1_section'>
                        <div className='row1_section_icon_wrapper'>
                            <img src={noHiddenFeesIcon} alt="no hidden fees icon"/>
                        </div>
                        <p className='row1_section_title'>No Hidden Fees</p>
                        <p className='row1_section_text'>You will only be charged when selling goods or requesting payment.</p>
                    </section>
                    <section className='row1_section'>
                        <div className='row1_section_icon_wrapper'>
                            <img src={securedIcon} alt="secured icon" />
                        </div>
                        <p className='row1_section_title'>Secure Payment</p>
                        <p className='row1_section_text'>Ensuring peace of mind with our secure payment solutions.</p>
                    </section>
                </div>
            </div>
            <div className='main_row2'>
                <img src={safeTransaction} alt="safe transaction"/>
                <section className='row2_section'>
                    <h2 className='row2_section_title'>Experience Safe Transactions</h2>
                    <p className='row2_section_text'>Experience worry-free transactions with our cutting-edge solutions, providing you the peace of mind that comes with secure and seamless payments for your goods and services.</p>
                    <button type='button' className='row2_get_started_btn' onClick={ () => navigate("signup")}>Get Started Now</button>
                </section>
            </div>
            <div className='main_row3'>
                <div className='row3_section'>
                    <h2 className='row3_section_title'>Accept Payment Globally</h2>
                    <p className='row3_section_msg'>Elevate your financial transactions on a global scale with our cutting-edge payment solutions. Experience seamless <br/> and secure cross-border transactions that transcend borders, offering you a world of convenience and efficiency.</p>
                </div>
                <div className='row3_globe_wrapper'>
                    <img className='globe_img' src={globeImage} alt="global network" />
                </div>
            </div>
            <div className='main_row4'>
                <h2 className='row4_section_title'>Manage recurring payments easily</h2>
                <div className='row4_section_container'>
                    <section className='row4_section_item'>
                        <img src={iconHolder}  alt="placeholder"/>
                        <h3 className='row4_section_sub_title'>Payment Plans</h3>
                        <p className='row4_section_msg'>Empower customers with personalized payment plans for flexible and hassle-free transactions.</p>
                    </section>
                    <section className='row4_section_item'>
                        <img src={apiIcon}  alt="api icon"/>
                        <h3 className='row4_section_sub_title'>API Integration</h3>
                        <p className='row4_section_msg'>Seamlessly integrate with our API for enhanced functionality and efficiency in your websites</p>
                    </section>
                </div>
            </div>
            <div className='main_row5'>
                <div className='row5_section_container'>
                    <img src={happy} alt="happy people" className='row5_img'/>
                </div>
                <div className='row5_section_container'>
                    <h2 className='row5_section_title'>Get The MoMonie App on <br/> Google Play Store or App Store</h2>
                    <p className='row5_section_msg'>Download our User App on Google Play or the App Store for <br/> convenient access and seamless experiences.</p>
                    <section className='row5_app_store_container'>

                    </section>
                </div>
            </div>
            <div className='main_row6'>
                <h2 className='row6_section_title'>What our customers are saying</h2>
                <div className='row6_section_container'>
                    <img src={review1} alt="review" className='section_review_comment'/>
                    <img src={review2} alt="review" className='section_review_comment'/>
                </div>
            </div>
        </main>
        <footer className='footer_container'>
            <div className='footer_info_container'>
                <div className='footer_section'>
                    <img src={ibills}  alt='ibils logo' className='amapgs_logo' />
                    <div className='poweredBy'><p className='poweredByTxt'>Powered by: Ojapay Nigeria Limited </p><img className="poweredByImg2" src={UBALogo} alt='Ojapay Logo'/></div>
                    <ul className='footer_icon_links'>
                        <li><a href='&'><img src={twitter} alt="twitter logo"/></a></li>
                        <li><a href='&'><img src={instagram} alt="instagram logo"/></a></li>
                        <li><a href='&'><img src={coolicon} alt="coolicon logo"/></a></li>
                    </ul>
                </div>
                <div className='footer_section2'>
                    <section className='footer_sub_section'>
                        <ul className='footer_icon_list'>
                            <li><a href='&'>About</a></li>
                            <li><a href='&'>Contact Us</a></li>
                            <li><a href="&">Benefits</a></li>
                        </ul>
                    </section>
                    <section className='footer_sub_section'>
                        <ul>
                            <li><a href='&'>Legal</a></li>
                            <li><a href='&'>Terms of Service</a></li>
                            <li><a href="&">Privacy Policy</a></li>
                        </ul>
                    </section>
                </div>
            </div>
            <hr className='landing_hr'/>
            <p className='footer_copyright_text'>&copy; 2024 Copyright Ojapay Nigeria Limited</p>
        </footer>
    </div>
  )
}

export default Landing