import React, { useEffect, useState } from "react";
import "./styles/login.css";
import merchHero from "./assets/1.png"
import iBillsLogo from "./assets/amaps.png"
// import iBillsLogo from "./assets/ibillslogo.png"
import { useNavigate } from "react-router-dom";
import HandleSubmit from "./components/HandleSubmit";
import { env } from "../shared/assets/environment/envSelector";
import { authState, setPacketStatus, setMessage, setIsAuthenticated, setGetUser } from '../shared/assets/slices/authSlice';
import { useDispatch } from "react-redux";

type Props = {}

const Login = (props: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [emailOrPhone, setEmailOrPhone] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [user, setUser] = useState<string>("")
  const { login } = env;

  const payload = {
    email: emailOrPhone,
    password: password
  }

  const handleLogin = async (e: any) => {
    e.preventDefault()
    const headers = {
      headers: { "Content-type": "application/json" },
    }
    await HandleSubmit(e, payload, login, "POST", setPacketStatus, setStatusMessage, setUser, undefined, headers);
  }

  useEffect(() => {
    if (user) {
      dispatch(setIsAuthenticated(true));
      dispatch(setGetUser(user));
      navigate("/merchant/dashboard");
    }
  }, [user, dispatch, navigate]);

  return (
    <main className='login'>
      <div className="column1_signin">
        <div className="wrapper1">
          <img src={iBillsLogo} className='amapgs_logo' alt="instant bills pay logo" onClick={() => navigate("/merchant")} />
          <form className='login_form'>
            <h2 className='login_title'>Merchant Login</h2>
            <div className='login_credentials'>
              <label className='email_phone_l'>Email or Phone Number<br></br>
                <input className='email+phone' type="text" onChange={(e) => setEmailOrPhone(e.target.value)} />
              </label><br></br>
              <label className='password_l'>Password<br></br>
                <input className='password' type="password" onChange={(e) => setPassword(e.target.value)} />
              </label><br></br>
            </div>
            <button type='submit' className="button1" onClick={handleLogin}>Login</button>
            <a href="$" className="forgot_password_link">Forgot Password?</a>
          </form>
          <h2 className="create_account_title">Don't have an account?</h2>
          <button className="button2" onClick={() => navigate("/merchant/signup")}>Create an Account</button>
        </div>
      </div>
      <div className='column2_signin'>
        <img src={merchHero} alt="hero_img" className="hero_img" />
      </div>

    </main>
  );
}

export default Login;
