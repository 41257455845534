import {Routes, Route} from 'react-router-dom'
import Landing from './components/Landing';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import NoticeBanner from '../merchant/components/noticebanner';


function App() {
  return (
    <div className="App">
      <NoticeBanner/>
      <Routes>
        <Route path="/" element={<Landing />} />   
        <Route path="/login" element={<Login />} />   
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;


