import {
    FaCircleLeft,
    FaMagnifyingGlass
} from "react-icons/fa6";
import "./styles/products.css"
import BuildProduct from "../builders/BuildProduct";
import Window from "../Window";
import { useNavigate } from "react-router-dom";

type Props = {}

const ProductsView = (props: Props) => {
    const navigate = useNavigate()
    const products = [
        {
            image: require("../assets/chocolateCake.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {   
            image: require("../assets/chocolateCake2.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake2.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake2.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        },
        {
            image: require("../assets/chocolateCake2.png"),
            qty: "10",
            price: "30000",
            name: "Chocolate Cake",
            text: "Indulge in pure decadence with our rich and moist chocolate cake at Sweet Bliss Bakes. Each slice is a symphony of velvety cocoa goodness, crafted with the finest ingredients to create a melt-in-your-mouth experience.....",
        }
    ]

    return (
        <div className="products_super_container">
            <div className="products_header_info">
                <div className="products_title_wrapper">
                    <FaCircleLeft size={30} color="#B20C02" onClick={ () => navigate(-1)}/>
                    <p className="products_header_title">Ani Cake Shops</p>
                </div>
                <button type="button" className="products_addNewproduct_btn">+ Add Product</button>
            </div>
            <hr className="products_hr" />
            <div className="products_cat_container">
                <div className="products_cat_info_wrapper">
                    <p className="products_cat_desc_title">Description</p>
                    <p className="products_text">Indulge your senses in a symphony of sweetness at our charming cake shop. Immerse yourself in a world of exquisite confections, where each slice is a masterpiece crafted with love and precision. </p>
                </div>
                <div>
                    <p className="products_header_title">My Products</p>
                    {products.map((product) => BuildProduct(product))}  
                </div>
            </div>
        </div>
  )
}

const Products = () => {
    const content = {
        currentView: <ProductsView />,
        activeId: 9
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}

export default Products