import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../../shared/assets/images/logo.png'
import '../../shared/assets/dropdown/css/style.css'
import '../../shared/assets/socicon/css/styles.css'
import '../../shared/assets/theme/css/style.css'
import '../../shared/assets/mobirise/css/mbr-additional.css'
import '../../shared/assets/mobirise/css/landing.css'
import '../../shared/assets/bootstrap/css/bootstrap.min.css'
import '../../shared/assets/bootstrap/css/bootstrap-grid.min.css'
import '../../shared/assets/bootstrap/css/bootstrap-reboot.min.css'
import '../../shared/assets/web/assets/mobirise-icons2/mobirise2.css'
import Logo from '../../shared/assets/images/amaps.png'
import slider1 from '../../shared/assets/images/guy smiling.jpg'
import slider2 from '../../shared/assets/images/guy-with-eyepiece.jpg'
import slider3 from '../../shared/assets/images/office-meeting.jpg'
import twitter from "../../shared/assets/images/twittter.png"
import instagram from "../../shared/assets/images/instagram.png"
import coolicon from "../../shared/assets/images/coolicon.png"
import fragrance from '../../shared/assets/images/shop1.jpg'
import skincare from '../../shared/assets/images/shop2.jpg'
import comfort from '../../shared/assets/images/shop3.jpg'
import essentials from '../../shared/assets/images/shop4.jpg'
import journey from '../../shared/assets/images/journey.jpg'
import image1 from '../../shared/assets/images/1.png'
import image2 from '../../shared/assets/images/2.png'
import image4 from '../../shared/assets/images/4.png'
import amapgs from '../../shared/assets/images/amaps.png'
import ojapay from '../../merchant/assets/ojapay logo.png'

type Props = {}

const Landing = (props: Props) => {
  const navigate = useNavigate()
  return (
    <div className='Landing'>
          <section
        data-bs-version="5.1"
        className="menu menu2 cid-tJS6tZXiPa"
        id="menu02-9"
      >
        <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg">
          <div className="container">
            <div className="navbar-brand">
              <span className="navbar-logo" >
                <a href="">
                  <img
                    className='amapgs_logo'
                    src={Logo}
                    alt="amapgs"
                    style={{width: '130px', height: '50px', borderRadius: '2rem !important'}}
                  />
                </a>
              </span>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-bs-toggle="collapse"
              data-target="#navbarSupportedContent"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div className="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav nav-dropdown" data-app-modern-menu="true">
                <li className="nav-item">
                  <a className="nav-link link text-black display-4" href="">About</a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link text-black display-4"
                    href=""
                    aria-expanded="false"
                  >Services</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link link text-black display-4" href=""
                  >Contacts</a>
                </li>
              </ul>
              <div className="navbar-buttons mbr-section-btn">
                <a className="btn btn-primary display-4" href=""> Login</a>
              </div>
              <div className="navbar-buttons mbr-section-btn">
                <a className="btn btn-primary display-4" href=""> Signup</a>
              </div>
            </div>
          </div>
        </nav>
      </section>

      <section
        data-bs-version="5.1"
        className="slider3 cid-uavyPcVPMG"
        id="slider03-f"
      >
        <div
          className="carousel slide"
          id="uavFgaorly"
          data-interval="5000"
          data-bs-interval="5000"
        >
          <ol className="carousel-indicators">
            <li
              data-slide-to="0"
              data-bs-slide-to="0"
              className="active"
              data-target="#uavFgaorly"
              data-bs-target="#uavFgaorly"
            ></li>
            <li
              data-slide-to="1"
              data-bs-slide-to="1"
              data-target="#uavFgaorly"
              data-bs-target="#uavFgaorly"
            ></li>
            <li
              data-slide-to="2"
              data-bs-slide-to="2"
              data-target="#uavFgaorly"
              data-bs-target="#uavFgaorly"
            ></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item slider-image item active">
              <div className="item-wrapper">
                <img
                  className="d-block w-100"
                  src={slider1}
                  alt=""
                />

                <div className="carousel-caption">
                  <h5 className="mbr-section-subtitle mbr-fonts-style mb-3 display-5">
                    <strong>Seamless Payments, Simplified</strong>
                  </h5>
                  <p className="mbr-section-text mbr-fonts-style mb-4 display-7">
                    Secure, reliable, and fast transactions, whether you're paying for products or services. Our trusted payment gateway handles it all with ease.
                  </p>
                  <div className="mbr-section-btn mb-5">
                    <a className="btn btn-primary display-7" href="/signup"
                    >Get started</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item slider-image item">
              <div className="item-wrapper">
                <img
                  className="d-block w-100"
                  src={slider2}
                  alt=""
                />

                <div className="carousel-caption">
                  <h5 className="mbr-section-subtitle mbr-fonts-style mb-3 display-5">
                    <strong>Discover a World of Product</strong>
                  </h5>
                  <p className="mbr-section-text mbr-fonts-style mb-4 display-7">
                    Explore top-quality goods from verified merchants across our extensive marketplace. Whatever you need, find it here.
                  </p>
                  <div className="mbr-section-btn mb-5">
                    <a className="btn btn-primary display-7" href="/signup"
                    >Get started</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item slider-image item">
              <div className="item-wrapper">
                <img
                  className="d-block w-100"
                  src={slider3}
                  alt=""
                />

                <div className="carousel-caption">
                  <h5 className="mbr-section-subtitle mbr-fonts-style mb-3 display-5">
                    <strong>Top-notch Services at Your Fingertips</strong>
                  </h5>
                  <p className="mbr-section-text mbr-fonts-style mb-4 display-7">
                    Connect with skilled professionals and service providers for all your personal and business needs, all in one place.
                  </p>
                  <div className="mbr-section-btn mb-5">
                    <a className="btn btn-primary display-7" href="/signup"
                    >Get started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            className="carousel-control carousel-control-prev"
            role="button"
            data-slide="prev"
            data-bs-slide="prev"
            href="#uavFgaorly"
          >
            <span
              className="mobi-mbri mobi-mbri-arrow-prev"
              aria-hidden="true"
            ></span>
            <span className="sr-only visually-hidden">Previous</span>
          </a>
          <a
            className="carousel-control carousel-control-next"
            role="button"
            data-slide="next"
            data-bs-slide="next"
            href="#uavFgaorly"
          >
            <span
              className="mobi-mbri mobi-mbri-arrow-next"
              aria-hidden="true"
            ></span>
            <span className="sr-only visually-hidden">Next</span>
          </a>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="header6 cid-uavxkVA3B9"
        id="header06-b"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="card-wrapper">
                <h3 className="mbr-section-cardtitle mbr-fonts-style mb-2 display-5">
                  <strong>Explore Endless Possibilities</strong>
                </h3>

                <p className="mbr-cardtext mbr-fonts-style mb-0 display-7">
                  Immerse yourself in a marketplace brimming with possibilities. From everyday products to unique finds, discover everything you need, curated just for you. Navigate the world of commerce with ease and let your choices know no bounds
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h1 className="mbr-section-title mbr-fonts-style mb-4 display-2">
                <strong>Seamless Shopping, Streamlined Payments</strong>
              </h1>

              <p className="mbr-text mbr-fonts-style mb-4 display-7">
                Our platform simplifies your shopping experience with a seamless fusion of diverse stores and straightforward payment solutions. Enjoy hassle-free transactions whether you're at the checkout or managing your business finances.
              </p>
              <div className="mbr-section-btn mt-4">
                <a className="btn btn-primary display-7" href=""
                >Start Shopping</a>
                <a
                  className="btn btn-success-outline display-7"
                  href=""
                >Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="slider1 mbr-embla cid-uavzjLbP3y"
        id="slider01-g"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div
                className="embla"
                data-skip-snaps="true"
                data-align="center"
                data-contain-scroll="trimSnaps"
                data-auto-play-interval="5"
                data-draggable="true"
              >
                <div className="embla__viewport">
                  <div className="embla__container">
                    <div
                      className="embla__slide slider-image item"
                      style={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      <div className="slide-content">
                        <div className="item-wrapper">
                          <div className="item-img">
                            <img src={fragrance} alt="" />
                          </div>
                        </div>
                        <div className="item-content">
                          <h5 className="item-title mbr-fonts-style mb-2 display-5">
                            <strong>Essence of Nature</strong>
                          </h5>

                          <p className="mbr-text mbr-fonts-style display-7">
                            Capture the essence of purity with our eco-friendly fragrances. Sourced from natural elements, each scent is crafted to elevate your mood and enhance your space.
                          </p>
                        </div>
                        <div className="mbr-section-btn item-footer mt-3">
                          <a href="" className="btn btn-primary item-btn display-7"
                          >Learn More</a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="embla__slide slider-image item"
                      style={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      <div className="slide-content">
                        <div className="item-wrapper">
                          <div className="item-img">
                            <img src={skincare} alt="" />
                          </div>
                        </div>
                        <div className="item-content">
                          <h5 className="item-title mbr-fonts-style mb-2 display-5">
                            <strong>Gentle on Skin, Tough on Impurities</strong>
                          </h5>

                          <p className="mbr-text mbr-fonts-style display-7">
                            Indulge in the luxury of our organic skincare range. Our gentle formulas are designed to nourish deeply while protecting your skin from everyday pollutants.
                          </p>
                        </div>
                        <div className="mbr-section-btn item-footer mt-3">
                          <a href="" className="btn btn-primary item-btn display-7"
                          >Learn More</a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="embla__slide slider-image item"
                      style={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      <div className="slide-content">
                        <div className="item-wrapper">
                          <div className="item-img">
                            <img src={comfort} alt="" />
                          </div>
                        </div>
                        <div className="item-content">
                          <h5 className="item-title mbr-fonts-style mb-2 display-5">
                            <strong>Soft Companions for Comforting Moment</strong>
                          </h5>

                          <p className="mbr-text mbr-fonts-style display-7">
                            Meet your child's new best friend. Our plush toys are more than just soft and cuddly – they're companions that bring warmth and joy to every hug.<br />
                          </p>
                        </div>
                        <div className="mbr-section-btn item-footer mt-3">
                          <a href="" className="btn btn-primary item-btn display-7"
                          >Learn More</a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="embla__slide slider-image item"
                      style={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      <div className="slide-content">
                        <div className="item-wrapper">
                          <div className="item-img">
                            <img src={essentials} alt="" />
                          </div>
                        </div>
                        <div className="item-content">
                          <h5 className="item-title mbr-fonts-style mb-2 display-5">
                            <strong>Green Living Essentials</strong>
                          </h5>

                          <p className="mbr-text mbr-fonts-style display-7">
                            Embrace sustainability with our eco-chic reusable bags, perfect for your daily errands. Paired with our lush, air-purifying plants, they're a stylish statement in environmental care and green living.<br />
                          </p>
                        </div>
                        <div className="mbr-section-btn item-footer mt-3">
                          <a href="" className="btn btn-primary item-btn display-7"
                          >Learn More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="embla__button embla__button--prev">
                  <span
                    className="mobi-mbri mobi-mbri-arrow-prev mbr-iconfont"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="sr-only visually-hidden visually-hidden visually-hidden"
                  >Previous</span>
                </button>
                <button className="embla__button embla__button--next">
                  <span
                    className="mobi-mbri mobi-mbri-arrow-next mbr-iconfont"
                    aria-hidden="true"
                  ></span>
                  <span
                    className="sr-only visually-hidden visually-hidden visually-hidden"
                  >Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        data-bs-version="5.1"
        className="article05 cid-uavA6myNZh"
        id="article05-i"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="card-wrapper">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-5 image-wrapper">
                    <img className="w-100" src={journey} alt="" />
                  </div>
                  <div className="col-12 col-lg col-md-12">
                    <div className="text-wrapper align-left">
                      <h1
                        className="mbr-section-title mbr-fonts-style mb-4 display-2"
                      >
                        <strong> Discover the Latest Trends</strong>
                      </h1>
                      <p className="mbr-text mbr-fonts-style mb-4 display-7">
Dive into our curated articles to stay updated with the latest trends in fashion, technology, lifestyle, and more. Explore insightful pieces written by industry experts and thought leaders, designed to inspire and inform.<br />
                      </p>
                      <div className="mbr-section-btn mt-3">
                        <a
                          className="btn btn-lg btn-black display-7"
                          href=""
                        >Get Started</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer_container">
        <div className="footer_info_container">
          <div className="footer_section">
            <img src={amapgs} alt="AMAPGS logo" className='amapgs_logo'/>
            <div className="poweredBy">
              <p className="poweredByTxt">Powered by: Ojapay Nigeria Limited</p>
              <img
                className="poweredByImg"
                src={ojapay}
                alt="Ojapay Nigeria Limited"
              />
            </div>
            <ul className="footer_icon_links">
              <li>
                <a href="&"><img src={twitter} alt="twitter logo" /></a>
              </li>
              <li>
                <a href="&"><img src={instagram} alt="instagram logo" /></a>
              </li>
              <li>
                <a href="&"><img src={coolicon} alt="coolicon logo" /></a>
              </li>
            </ul>
          </div>
          <div className="footer_section2">
            <section className="footer_sub_section">
              <ul className="footer_icon_list">
                <li><a href="&">Login</a></li>
                <li><a href="&">Apps</a></li>
                <li><a href="&">Community</a></li>
              </ul>
            </section>
            <section className="footer_sub_section">
              <ul>
                <li><a href="&">Legal</a></li>
                <li><a href="&">Terms of Service</a></li>
                <li><a href="&">Privacy Policy</a></li>
              </ul>
            </section>
          </div>
        </div>
        <hr className="landing_hr" />
        <p className="footer_copyright_text">
          &copy; 2024 Copyright By Ojapay Nigeria Limited
        </p>
      </footer>
    </div>
  )
}

export default Landing