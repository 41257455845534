import "./styles/requestPayoutModal.css"
const RequestPayoutModal = (setShowReqPayout: any, setBlur: any) => {
  return (
    <div className='req_payout_modal_wrapper'>
        <div className="req_payout_title_div">
            <p className="req_payout_title">Request Payout</p>
            <button type="button" className="req_payout_modal_close_btn" onClick={() => {setShowReqPayout(false); setBlur(false)}}>X</button>
        </div>
        <form className='req_payout_form'>
            <label className='req_payout_form_label'>
                Amount
                <input className='req_payout_form_input'/>
            </label>
            <hr className="req_payout_hr"/>
            <div className="req_payout_modal_btns_div">
                <button className="req_payout_modal_cancel_btn" type="button" onClick={() => {setShowReqPayout(false); setBlur(false)}}>Cancel</button>
                <button className="req_payout_modal_submit_btn" type="submit">Request</button>
            </div>
        </form>
        
    </div>
  )
}

export default RequestPayoutModal