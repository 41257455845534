import React from 'react'
import "./styles/login.css"
import InstantBills from "../assets/amaps.png"

const Login = () => {
  return (
    <div className='login_super_container'>
        <img src={InstantBills} alt="Instantbills Logo" className='amapgs_logo'/>
        <form className='admin_login_form'>
            <h1 className='login_form_title'>Admin Login</h1>
            <label className='form_label'>
                Email
                <input type='email' className='form_input'required/>
            </label>
            <label className='form_label'>
                Password
                <input type='password' className='form_input' required/>
            </label>
            <button className='login_btn' type='submit'>Login</button>
        </form>
    </div>
  )
}

export default Login