import React from 'react'
import Window from './Window';

type Props = {}

const DashboardView = (props: Props) => {

  return (
<div></div>
  )
}
const Dashboard = () => {
    const content = {
        currentView: <DashboardView />,
        activeId: 1
    }
    return(
        <Window currentView={content.currentView} activeId={content.activeId}/>
    )
}
export default Dashboard