const debug: string = "FALSE"

const devUrl = "http://127.0.0.1:8000/api/onboarding/"

const prodUrl = "https://merchantservice.ojapay.com/api/onboarding/"

const baseUrl = debug === "TRUE" ? devUrl : prodUrl


export const env = {
    login: baseUrl + "merchant-login/",
    signup: baseUrl + "create-merchant/",
    logout: baseUrl + "logout/",
    getEmailOtp: baseUrl + "otp-email/",
    getPhoneOtp: baseUrl + "otp-phone-number/",
    verifyOtp: baseUrl + "email-phone-verification/",
    busVerification: baseUrl + "business-verification/",
    idChoices: baseUrl + "id-choices/",
    bankChoices: baseUrl + "bank-choices/",
}                                                                               