import {useState} from 'react'
import "./styles/buildTransaction.css"
import { useNavigate } from 'react-router-dom'

type BuildTransactionProps = {
    refNo?: string,
    customerName: string,
    date: string,
    item: string,
    successStatus: boolean,
    paymentMethod: string,
    paymentAmount: string
}

const BuildTransaction = (props: BuildTransactionProps) => {
  const navigate = useNavigate()

  return (
    <>
        <div className="translog_section">
            { props.refNo &&
                <p className="text_ln">{props.refNo}</p>
            }
            <p className="text_ln">{props.customerName}</p>
            <p className="text_ln">{props.date}</p>
            <p className="text_ln">{props.item} </p>
            <div className="text_ln">
                <p className={props.successStatus? "status_successful" : "status_failed"}>{props.successStatus? "Successfull" : "Failed"}</p>
            </div>
            <p className="text_ln">{props.paymentMethod}</p>
            <p className="text_ln">{props.paymentAmount}</p>
        </div>
        <hr className="translog_hr"/>
    </>
  )
}

export default BuildTransaction