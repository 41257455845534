import React from 'react'
import './styles/buildTransactionLog.css'

type TransLogProps = {
    id: number,
    name: string,
    amount: string,
    merchant: string,
    status: boolean,
    tableTitle: string
}

const BuildTransactionLog = (transaction: TransLogProps) => {
  return (
    <div className={transaction.id % 2 === 0? 'trans_sec gray' : 'trans_sec '}>
        <p className='trans_txt'>{transaction.name}</p>
        <p className='trans_txt'>{transaction.amount}</p>
        <p className='trans_txt'>{transaction.merchant}</p>
        <p className={transaction.status? 'trans_txt successful': 'trans_txt failed'}>
            {transaction.status? "Successful" : "Failed"}
        </p>
        <div className='trans_txt'>
            <p className='table_title '>{transaction.tableTitle}</p>
        </div>

    </div>
  )
}

export default BuildTransactionLog